import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Contact = () => {
  // https://docs.followupboss.com/docs/start-here-brand-new-integration
  // POST https://api.followupboss.com/v1/events
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = {
      firstName: firstName,
      lastName: lastName,
      message: message,
      email: email,
      phoneNumber: phoneNumber,
    };

    fetch("api/sendNewLead", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setShowSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        setShowError(true);
      });
  };

  return (
    <div>
      <Header />

      <main>
        {/* Page Heading */}
        <div className="contact-form-heading">
          <h2>Contact Us</h2>
          <p>Fill out the form below and we'll be in contact tomorrow.</p>
          <hr className="contact-form-hr" />
        </div>

        {/* The Form */}
        <div className="contact-form-container">
          <div className="contact-main-wrapper">
            <div className="contact-form-wrapper">
              <form onSubmit={handleFormSubmit}>
                <div className="contact-input-flex">
                  <div>
                    <label htmlFor="firstname" className="contact-form-label">
                      First name *
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder="First Name"
                      className="contact-form-input"
                      required
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="lastname" className="contact-form-label">
                      Last name *
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Last Name"
                      className="contact-form-input"
                      required
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="contact-input-flex">
                  <div>
                    <label htmlFor="email" className="contact-form-label">
                      {" "}
                      Email *
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="example@mail.com"
                      className="contact-form-input"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="contact-form-label">
                      Phone *
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="(319) 555-0115"
                      className="contact-form-input"
                      required
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="message" className="contact-form-label">
                    Message *
                  </label>
                  <textarea
                    rows="6"
                    name="message"
                    id="message"
                    placeholder="Type your message"
                    className="contact-form-input"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>

                {showSuccess ? (
                  <div className="message-container">
                    <p className="success-message">
                      Your message has sent, we'll be in contact with you
                      shortly!
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                {showError ? (
                  <div className="message-container">
                    <p className="error-message">
                      Something has gone wrong with your request, please try
                      again later.
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    marginTop: "20px",
                    color: "#07074d",
                  }}
                >
                  <input type="checkbox" required></input>
                  <p style={{ marginBlockStart: "0" }}>
                    I agree to be contacted by Find a Home Inc. via call, email,
                    and text. To opt out, you can reply 'stop' at any time or
                    click the unsubscribe link in the emails. Message and data
                    rates may apply. <a href="/privacy">Privacy Policy</a>
                  </p>
                </div>

                <button className="contact-btn text-shadow" type="submit">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Contact;
